jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    if (ns.includes("noPreventDefault")) {
      this.addEventListener("touchstart", handle, { passive: false });
    } else {
      this.addEventListener("touchstart", handle, { passive: true });
    }
  }
};

jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    if (ns.includes("noPreventDefault")) {
      this.addEventListener("touchmove", handle, { passive: false });
    } else {
      this.addEventListener("touchmove", handle, { passive: true });
    }
  }
};


AOS.init();


$('table').wrap("<div class='table-responsive'></div>");


$(".navbar-icon").on('click', function () {
  $(this).toggleClass("active");
  $(".navbar-menu nav").toggleClass("active");
  $(".navbar").toggleClass("active");
  $("a.level1").toggleClass("opened");
});


$("a.level1").on('click', function () {
  $(".navbar-icon").removeClass("active");
  $(".navbar-menu nav").removeClass("active");
  $(".navbar").removeClass("active");
});



